@import "@/assets/scss/style.scss";

.sider {
    background-color: $white-two;
}

.ant-card {
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 7px;
}
.ant-card-small > .ant-card-body {
    padding: 6px;
}

.ant-card-grid {
    float: left;
    padding: 8px;
    border: 0;
    border-radius: 0;
    box-shadow: none !important;
}
.bold {
    font-size: 0.9rem;
    font-weight: 450;
    color: #262626;
}
.bold-small {
    font-size: 0.8rem;
    font-weight: 400;
    color: #262626;
}
.thin {
    font-size: 0.9rem;
    font-weight: 300;
    color: #8c8c8c;
}
.ant-divider-horizontal {
    margin: 0px 0px !important;
}

.first_col {
    padding: 8px;
    text-align: left;
}

.second_col {
    padding: 8px;
    text-align: right;
}
