@import "@/assets/scss/style.scss";

.ant-layout-content {
  overflow: hidden;
  border-left: 1px solid $grey-4;
}

.sider {
  background-color: #fff;
}

#components-layout-demo-basic {
  text-align: center;
}
#components-layout-demo-basic .ant-layout-header,
#components-layout-demo-basic .ant-layout-footer {
  background: white;
}
#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
}
#components-layout-demo-basic .ant-layout-content {
  background-color: #fff;
  min-height: 100vh;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
